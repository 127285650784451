:root {
    // sidebar
    --sidebar-width: 80px;
    --sidebar-width-hover: 294px;
    --sidebar-padding-right-hover: 12px;
    --sidebar-logo-display: none;
    --sidebar-subsection-opacity: 1;
    --sidebar-legals-buttons-display: none !important;
    --sidebar-legals-icon-display : block !important;
    --sidebar-legals-buttons-overflow: inherit;
    --sidebar-link-subsection-margin:  auto 15px;
    --sidebar-container-sublinks-border-left: 2px solid var(--grey-30);
    --sidebar-container-sublinks-padding-left: 16px;
    --sidebar-filters-icon-display: block;
    --sidebar-filters-label-display: none;
    --sidebar-display-campain: none;

    // security
    --content-width: 654px;  // timeline width + status-sidebar width + 24px of margin + 24px of grid gap
    --timeline-width: 438px;
    --status-sidebar-width: 168px;
    --camera-snapshot-width: 168px;
    --timeline-snapshot-width: 296px;
    --timeline-snapshot-height: 166px;
    --live-player-height: 246px;
    --person-list-display: none;
    --person-list-small-display: flex;
    --person-box-top: 176px;
    --person-box-width: 360px;
    --home-away-min-height: 136px;

    // control
    --aside-sidebar-width: 236px;
    --home-modes-flex-direction: column;
    --homes-modes-max-width: none;
    --display-dataset-preview: none;
    --consommation-sidebar-small-display: flex;
    --consommation-sidebar-large-display: none;
    --control-body-cards-direction: column;
    --left-sidebar-max-width-air-quality: 100px;
    --home-mode-selection-width: 50%;
    --padding-top-setpoint: 16px;
    --gap-page-layout: 24px;

    // report
    --display-main-card: block;
    --main-card-flex-direction: column;
    --max-width-month-selector: auto;
    --height-explanatories-factors: auto;
    --padding-top-factors-no-data: 24px;
    --justify-content-months: center;
    --justify-content-month-old: center;
    --min-width-months: 120px;
    --margin-months: 16px;
    --width-bottom-cards: 100%;
    --width-months-legend: 100px;
    --margin-month-selector: 12px;
    --display-reports-header: none;
    --reports-months-height: 150px;
    --flex-direction-setpoint-section: column;

    // reports skeleton 
    --width-large-skeleton-text: 200px;
    --min-width-skeleton: 300px;
    //settings
    --temperature-schedule-width: 37.5rem;
    

    // tablet lanscape
    @media (min-width:1024px) {
        --content-width: 811x;  // timeline width + status-sidebar width + 24px of margin + 24px of grid gap
        --timeline-width: 595px;
        --timeline-snapshot-width: 379px;
        --timeline-snapshot-height: 213px;
        --live-player-height: 335px;
        --temperature-schedule-width: 40rem;
        --min-width-months: 150px;
        --width-months-legend: 150px;
        --display-reports-header: default;
        --flex-direction-setpoint-section: row;
        --reports-months-height: 200px;

        --width-large-skeleton-text: 250px;
        --min-width-skeleton: 480px;

    }
    // xx small desktop
    @media(min-width:1200px) {
        --content-width: 914px;  // timeline width + status-sidebar width + 24px of margin + 24px of grid gap
        --timeline-width: 698px;
        --timeline-snapshot-width: 497px;
        --timeline-snapshot-height: 280px;
        --live-player-height: 392px;

        // settings
        --temperature-schedule-width: 47rem;
    }

    // x small desktop
    @media(min-width: 1366px) {
        --content-width: 1114px;  // timeline width + status-sidebar width + 24px of margin + 24px of grid gap
        --timeline-width: 706px;
        --status-sidebar-width: 360px;
        --camera-snapshot-width: 360px;
        --timeline-snapshot-width: 505px;
        --timeline-snapshot-height: 284px;
        --live-player-height: 397px;
        --person-list-display: flex;
        --person-list-small-display: none;
        --person-box-top: 95px;
        --person-box-width: 360px;
        --home-away-min-height: 48px;
        --sidebar-filters-icon-display: none;
        --sidebar-filters-label-display: block;
        --min-width-months: 150px;
        --reports-months-height: 300px;

        --aside-sidebar-width: 384px;
        --left-sidebar-max-width-air-quality: none;
        --home-modes-flex-direction: row;
        --home-mode-selection-width: auto;
        --homes-modes-max-width: 160px;
        --padding-top-setpoint: 0;
        --display-dataset-preview: visible;
        --consommation-sidebar-small-display: none;
        --consommation-sidebar-large-display: flex;

        // settings
        --temperature-schedule-width: 50rem;

        // report 
        --display-main-card: flex;
        --main-card-flex-direction: row;
        --max-width-month-selector: none;
        --margin-month-selector: none;
        --height-explanatories-factors: calc(100% - 48px);
        --padding-top-factors-no-data: 0;
        --justify-content-months: normal;
        --justify-content-month-old: space-around;
        --min-width-months: 130px;
        --margin-months: 0;
        --width-bottom-cards: calc(50% - 12px);
        --reports-months-height: 250px;
    }

    // small desktop
    @media(min-width: 1500px) {
        --control-body-cards-direction: row;
        --gap-page-layout: 40px;

        // settings
        --temperature-schedule-width: 53rem;
    }

    @media(min-width: 1600px) {
        --sidebar-width: 282px;
        --sidebar-width-hover: 282px;
        --sidebar-padding-right-hover: 0px;
        --sidebar-logo-display: block;
        --sidebar-subsection-opacity: 0;
        --sidebar-container-sublinks-margin : 16px 0 0 24px;
        --sidebar-legals-buttons-display: block !important;
        --sidebar-legals-icon-display : none !important;
        --sidebar-legals-buttons-overflow: hidden;
        --sidebar-link-subsection-margin: 12px;
        --sidebar-display-campain: block;
    }

    // medium desktop
    @media(min-width: 1680px) {
        --temperature-schedule-width: 55rem;
    }
    // large desktop
    @media (min-width: 1920px) {
    }

    // x large desktop
    @media (min-width: 2160px) {
    }
    app-dropdown {
        button {
            color: var(--grey-70);
        }
    }
}
